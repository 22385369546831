@font-face {
    font-family: 'tiempos';
    src: url('../assets/fonts/TiemposHeadlineApp-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia-Pro-Light';
    src: url('../assets/fonts/SofiaProLight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia-Pro-Regular';
    src: url('../assets/fonts/SofiaProRegular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia-Pro-Medium';
    src: url('../assets/fonts/SofiaProMedium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia-Pro-Bold';
    src: url('../assets/fonts/SofiaProBold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia-Pro-Black';
    src: url('../assets/fonts/SofiaProBlack.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }