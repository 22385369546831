.logo {
  margin-top: 50px;
  margin-bottom: 33px;
}

.contentTitle {
  max-width: 500px;
}

.contentTitle p {
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1.53px;
  font-size: 50px;
}

.containerOptions {
  margin-top: 3rem;
}

.contentSubtitle {
  max-width: 487px;
  margin-top: 32px;
}

.contentSubtitle p {
  text-align: center;
  letter-spacing: -1.23px;
  opacity: 0.99;
  font-size: 27px;
}

@media (max-width: 768px) {
  .contentSubtitle p {
    font-size: 20px
  }
}
