.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  margin-top: 50px;
  margin-bottom: 33px;
}

.contentTitle {
  max-width: 387px;
}

.contentTitle p {
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1.53px;
  font-size: 55px;
}

.contentSubtitle {
  margin-top: 20px;
  max-width: 389px;
}

.contentSubtitle p {
  text-align: center;
  letter-spacing: -1.23px;
  opacity: 0.99;
  font-size: 27px;
}

.wrapperForm {
  margin-top: 50px;
  min-width: 464px;
  position: relative;
}

.inputError {
  position: absolute;
  top: 73px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.contentButton {
  max-width: 238px;
  margin: 0 auto;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 30px 0;
  }
  .contentTitle {
    max-width: 296px;
  }
  .contentSubtitle {
    max-width: 296px;
  }
  .contentTitle p {
    font-size: 35px;
  }
  .contentSubtitle p {
    font-size: 20px
  }
  .contentBody p {
    font-size: 20px
  }
  .wrapperForm {
    min-width: 300px;
  }
}
