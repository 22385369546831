.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: -1;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
