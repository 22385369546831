.logo {
  margin-top: 50px;
  margin-bottom: 33px;
}

.contentTitle {
  max-width: 500px;
}

.contentTitle p {
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1.53px;
  font-size: 50px;
}

.contentSubtitle {
  margin-top: 20px;
}

.contentSubtitle p {
  text-align: center;
  letter-spacing: -1.23px;
  opacity: 0.99;
  font-size: 27px;
}

.wrapperOptions {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentBody p {
  letter-spacing: -1px;
  font-size: 27px;
}

.confirmBtn {
  width: 267px;
  z-index: 3;
}

.containerModal {
  z-index: 5;
}

@media (max-width: 768px) {
  .contentTitle {
    max-width: 296px;
  }
  .contentTitle p {
    font-size: 35px;
  }
  .contentSubtitle p {
    font-size: 20px
  }
  .contentBody p {
    font-size: 20px
  }
}

@media (min-width: 1025px) {
  .wrapperOptions {
    margin-top: 50px;
  }
  .confirmBtn {
    bottom: 50px;
  }
}
