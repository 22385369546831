.logo {
  margin-top: 50px;
  margin-bottom: 33px;
}

.contentTitle {
  max-width: 600px;
  margin-top: 12px;
}

.contentTitle p {
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1.53px;
  font-size: 50px;
}

.contentSubTitle {
  margin-top: 128px;
}

.contentSubTitle p {
  font-weight: 600;
  opacity: 0.52;
  font-size: 32px;
}

.contentButton {
  margin-top: 50px;
  width: 240px;
}
